import React from 'react';

function MoreApps(props) {
    return (
        <>
        <p className="text-2xl font-Lato text-center mt-6">More Apps</p>
            <div className="flex flex-wrap justify-around mt-6">
                <div className="flex flex-row justify-around ">
                    <a href="https://play.google.com/store/apps/details?id=sj.videoconverter">
                        <img src={process.env.PUBLIC_URL + "video_converter_icon.png"} className="object-contain h-28 w-56" alt="logo"/>
                        <p className="text-black font-ReemKufi text-1xl mb-14 mt-2 text-center">Video Converter</p>
                    </a>
                </div>

                <div className="flex flex-row justify-around">
                    <a href="https://play.google.com/store/apps/details?id=imagetopdf.jpgtopdf.converter.phototopdf">
                        <img src={process.env.PUBLIC_URL + "image_to_pdf_icon.png"} className="object-contain h-28 w-56" alt="logo"/>
                        <p className="text-black font-ReemKufi text-1xl mb-14 mt-2 text-center">Image to PDF</p>
                    </a>
                </div>

                <div className="flex flex-row justify-around">
                    <a href="https://play.google.com/store/apps/details?id=sj.textrepeaterfun">
                        <img src={process.env.PUBLIC_URL + "text_repeater_icon.png"} className="object-contain h-28 w-56" alt="logo"/>
                        <p className="text-black font-ReemKufi text-1xl mb-14 mt-2 text-center">Text Repeater</p>
                    </a>
                </div>

            </div>
        </>
    );
}

export default MoreApps;