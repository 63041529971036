import './App.css';
import Navbar from './components/Navbar';
import FeaturedApp from './components/FeaturedApp';
import MoreApps from './components/MoreApps';
import Footer from './components/Footer';

function App() {
  return (
    <>
        <Navbar />
        <FeaturedApp />
        <MoreApps />
        <Footer />
     
    </>
  );
}

export default App;
