import React from 'react';

function FeaturedApp(props) {
    return (
        <>
            <div className="md:flex md:flex-row justify-around bg-gradient-to-r from-orange-800 to-red-500">
                <div className="md:flex items-center">
                    <div className="md:flex flex-col items-center">
                        <h3 className="text-white font-mono text-2xl mb-3 text-center underline">Featured App</h3>
                        <h3 className="text-white font-Lato text-3xl mb-3 text-center">Video Splitter</h3>
                        <h5 className="text-white font-mono mb-6 text-center">Split long video into parts, also support Crop and Cut Video.</h5>
                        <div className="flex flex-row justify-around">
                            <a href="https://play.google.com/store/apps/details?id=sj.videosplitter">
                                <img src={process.env.PUBLIC_URL + "play_badge.png"} className="object-contain h-38 w-56" alt="Download from Google Play Store"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-around">
                    <img src={process.env.PUBLIC_URL + "only_sc.png"} className="object-contain h-58 w-96" alt="screenshot"/>
                </div>
            </div>
        </>
    );
}

export default FeaturedApp;