import React from 'react';

function Footer(props) {
    return (
        <footer class="p-4 bg-white shadow flex text-center items-center justify-around p-6 dark:bg-gray-800">
    <span class="text-sm text-gray-500 text-center dark:text-gray-400">Copyright © <a href="https://appsdevtech.com/" class="hover:underline">Apps Dev Tech</a>, 2024
    </span>
</footer>
    );
}

export default Footer;